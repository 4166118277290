import React from "react";
import AuthenticatedDataView from "../../components/AuthenticatedDataView";
// import ListingsBySuburb from "../../components/listings/ListingsBySuburb";
import {endpoint} from "../../lib/endpoint";

export default ({ params }) => {
  return <>
    <div>suburb: {params.id}</div>
    <AuthenticatedDataView fetchUrl={`${endpoint}/suburbs/${params.id}`}
      render={data => {
        return <div>data: ${JSON.stringify(data)}</div>
      }}
    />
  </>;
}

